/* eslint-disable react/display-name */
import React from 'react';
import {MDXProvider} from '@mdx-js/react';
import {MDXRenderer} from 'gatsby-plugin-mdx';

import {Section, SectionHeader} from 'src/components/molecules/Section';
import {Grid} from 'src/components/molecules/Grid';
import {Card} from 'src/components/atoms/Card';
import {List, ListItem} from 'src/components/atoms/List';
import {Icon} from 'src/components/atoms/Icon';
import {Button} from 'src/components/atoms/Button';
import {InlineList} from 'src/components/molecules/InlineList';
import {FluidImg} from 'src/embeds/image/image-dato';
import styled, {css} from "styled-components";

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  
   ${({secondCard}) =>
    secondCard &&
    css`
      background: #4123E6;
    ` || css`
    background: transparent;
    box-shadow: none;
    
    &:after {
        content: '+';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 36px;
        font-weight: bold;
    }
    
    @media (max-width: 799px) {
     &:after {
        transform: none;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
     }
    }
    `}
`;

function createColumns(n) {
    return Array(n).fill('1fr').join(' ');
}

export default function Drawer({
                                   heading,
                                   content,
                                   contentNode,
                                   callouts,
                                   ctas,
                                   customData,
                                   backgroundImage,
                               }) {
    callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
    customData = JSON.parse(customData);
    return (
        <Section
            {...customData?.flags}
            backgroundImage={
                !!backgroundImage && (
                    <FluidImg pictureStyle={{objectFit: 'cover'}} maxWidth={null} data={backgroundImage}/>
                )
            }
        >
            <SectionHeader>
                <h2>{heading}</h2>
                {content && <div dangerouslySetInnerHTML={{__html: contentNode.childMarkdownRemark.html}}/>}
            </SectionHeader>
            {callouts.length > 0 ? (
                <Card secondary>
                    <Grid columns={createColumns(customData?.columnCount ?? callouts.length)}>
                        {callouts.map(({heading, content, contentNode, media}, i) => {
                            return (
                                <Container key={i} secondary secondCard={i === 1}>
                                    <center>
                                        <h3 style={{minHeight: '3rem'}}>{heading}</h3>
                                    </center>
                                    <MDXProvider
                                        components={{
                                            ul: List,
                                            li: ({children}) => <ListItem
                                                icon={<Icon icon="check-circle"/>}>{children}</ListItem>,
                                        }}
                                    >
                                        <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>
                                    </MDXProvider>
                                </Container>
                            )
                        })}
                    </Grid>
                </Card>

            ) : null}
            {ctas.length > 0 && (
                <InlineList centered style={{width: '100%'}}>
                    {ctas.map((cta, i) => {
                        const ctaFlags = i > 0 ? {secondary: true} : {};
                        return (
                            <li key={i}>
                                <Button to={cta.url} {...ctaFlags}>
                                    {cta.text}
                                </Button>
                            </li>
                        );
                    })}
                </InlineList>
            )}
        </Section>
    );
}
